
.align-right {
  text-align: right !important;
}

.align-left {
  text-align: left !important;
}

.text-bold {
  font-weight: 700 !important;
  padding-left: 24px;
  margin-left: 0px !important;
}

.no-border {
  border: none !important;
}

.object-fit-cover {
  object-fit: cover;
}