@import '../../../../../../shared/static/styles/partials/variables';

.circleErr {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border: 3px solid $insight-error;
  border-radius: 50%;
  margin-top: 30px;
  margin-bottom: 30px;
  &::after {
    content: '';
    width: 24px;
    height: 3px;
    background-color: $insight-error;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -1px;
    margin-left: -12px;
    transform: rotate(-45deg);
  }
  &::before {
    content: '';
    width: 24px;
    height: 3px;
    background-color: $insight-error;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -12px;
    margin-top: -1px;
    transform: rotate(45deg);
  }
}
.circleSuccess {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border: 3px solid $insight-secondary;
  border-radius: 50%;
  margin-top: 30px;
  margin-bottom: 30px;
  i {
    color: $insight-secondary;
  }
}

.circleLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border: 0px solid $insight-secondary;
  border-radius: 50%;
  margin-top: 30px;
  margin-bottom: 30px;
  i {
    color: #fff;
  }
}
