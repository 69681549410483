@import '../../../../../shared/static/styles/partials/variables';

.tooltip {
  box-shadow: 0px 4px 4px 0px rgba(50, 75, 90, 0.02) !important;
  max-width: 300px;
  padding: 15px !important;
  line-height: 21px;
  border-radius: 4px !important;
  border: 1px solid $insight-orange !important;
  color: $insight-orange !important;
  background-color: rgba($color: $insight-orange, $alpha: 0.07);
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  margin-right: 10px;
  position: relative;
  &::before {
    content: '';
    background-color: white;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 4px;
  }
  a {
    color: $insight-orange;
  }
}

.scroll {
  max-height: 250px;
}

.option {
  max-width: 175px;

  .radio {
    min-width: 16px;
  }
}

.dummy {
  color: $insight-light-gray;
  cursor: default;
  pointer-events: none;
}
