.blinking {
  animation-name: blinkOption;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-iteration-count: 3;
  animation-fill-mode: forwards;
}

@keyframes blinkOption {
  from {
    background-color: white;
  }
  to {
    background-color: #deebff;
  }
}
