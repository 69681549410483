@import '../../../../../shared/static/styles/partials/variables';

.countColor {
  color: #68c7c2;
}

.container {
  min-height: auto !important;
}

.table {
  min-height: auto;
  thead {
    th {
      padding: 0 16px !important;
    }
  }
  tr {
    td {
      height: 75px;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      padding-left: 0px !important;
      padding-right: 0px !important;
      .wrapContent {
        padding: 16px 16px;
      }
      div {
        width: 100%;
      }
    }
  }
}

.refresh {
  height: 62px;
  align-items: center;
}

.recipients {
  max-width: 100px;
}

.actions {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 180px;
  height: 62px;

  a {
    height: 42px;
  }

  button {
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 10px;
    font-weight: 700;
    line-height: 15px;
    color: $insight-gray;
    text-transform: uppercase;
    transition: color 0.25s ease-in-out;
    padding: 0px 5px;
    i {
      color: $insight-primary;
      margin-bottom: 6px;
      transition: color 0.25s ease-in-out;
    }

    &:hover,
    &:focus {
      color: $insight-secondary;
      i {
        color: $insight-secondary;
      }
    }
    &:disabled {
      pointer-events: none !important;
      cursor: default !important;
      opacity: 0.6;
    }
  }
}

.fitContent {
  width: fit-content;
}

.disabledLink {
  pointer-events: none !important;
  cursor: default !important;
  opacity: 0.6;
}

.emptyAlert {
  max-width: 400px;
  margin: 40px auto;
  position: relative !important;
  transform: none !important;
  top: auto !important;
  left: auto !important;
}
