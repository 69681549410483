@import '../../../../../shared/static/styles/partials/variables';

.divider {
  border-color: $insight-light-gray;
  margin-top: 19px;
  margin-bottom: 40px;

  &.sub {
    margin-top: 13px;
    margin-bottom: 10px;
  }
}

.itemGap {
  margin-bottom: 32px;
}

.item {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba($color: $insight-primary, $alpha: 0.02);
  padding: 27px 32px;
  max-height: 244px;
  &.dummy {
    border: 1px solid $insight-orange;
    margin-bottom: 50px;
  }

  &.collapse {
    height: auto;
    max-height: none;

    .checkboxList {
      margin-bottom: 77px;
    }

    .checkbox {
      display: grid;
      grid-template-columns: 16px auto;
      align-items: center;
      grid-gap: 24px;
      color: $insight-gray;
      width: fit-content;
      &.checked {
        color: $insight-primary;
      }
    }

    textarea {
      min-height: 99px;
    }

    .formWrap {
      margin-bottom: 45px;
    }
  }

  p {
    margin-bottom: 0;
  }
}
