.blur {
  animation-name: filterChange;
  animation-duration: 0.2s;
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.clear {
  animation-name: filterChangeUp;
  animation-duration: 0.1s;
  animation-timing-function: ease-in;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes filterChange {
  from {
    filter: blur(40px);
  }
  to {
    filter: blur(20px);
  }
}

@keyframes filterChangeUp {
  from {
    filter: blur(20px);
  }
  to {
    filter: blur(0px);
  }
}
