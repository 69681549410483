@import '../../../../../shared/static/styles/partials/variables';

.countColor {
  color: #68c7c2;
}

.table {
  thead {
    th {
      padding: 0 16px !important;
    }
  }
  tr {
    td {
      height: 75px;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      padding-left: 0px !important;
      padding-right: 0px !important;
      .wrapContent {
        padding: 16px 16px;
      }
      div {
        width: 100%;
      }
    }
  }
}

.actions {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 180px;
  height: 62px;

  a {
    height: 42px;
  }

  button {
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 10px;
    font-weight: 700;
    line-height: 15px;
    color: $insight-gray;
    text-transform: uppercase;
    transition: color 0.25s ease-in-out;
    padding: 0px 5px;
    i {
      color: $insight-primary;
      margin-bottom: 6px;
      transition: color 0.25s ease-in-out;
    }

    &:hover,
    &:focus {
      color: $insight-secondary;
      i {
        color: $insight-secondary;
      }
    }
    &:disabled {
      pointer-events: none !important;
      cursor: default !important;
      opacity: 0.6;
    }
  }
}

.fitContent {
  width: fit-content;
}

.refresh {
  height: 62px;
  align-items: center;
}

.disabledLink {
  pointer-events: none !important;
  cursor: default !important;
  opacity: 0.6;
}

.emptyAlert {
  max-width: 400px;
  margin: 40px auto;
  position: relative !important;
  transform: none !important;
  top: auto !important;
  left: auto !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 62px;
  height: 24px;
  margin-bottom: 5px;
  margin-top: 5px;
  cursor: pointer;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  &.disable {
    pointer-events: none;
    opacity: 0.4;
  }

  .track {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    border: 1px solid $insight-light-gray;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 15px;
    box-shadow: 0px 2px 0px rgba($insight-light-gray, 0.4);
  }

  .slider {
    position: absolute;
    font-size: 11px;
    line-height: 23px;
    color: white;
    text-align: center;
    height: 24px;
    width: 40px;
    left: 0px;
    bottom: 0px;
    background-color: $insight-gray;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 15px;
    border: 1px solid $insight-gray;
    z-index: 1;
  }

  input:checked + .slider {
    background-color: $insight-secondary;
    border-color: $insight-secondary;
  }

  input:checked + .slider {
    -webkit-transform: translateX(22px);
    -ms-transform: translateX(22px);
    transform: translateX(22px);
  }
}
