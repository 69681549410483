.surveysTabPane {
    margin-top: 65px !important;
    min-height: 70px !important;
    padding: 56px 40px 0 !important;
}
  
.tab {
    &:focus {
        background-color: transparent !important;
    }
}