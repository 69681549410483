@import '../../../../../../../shared/static/styles/partials/variables';

.noTransform {
  text-transform: none !important;
}

.wrap {
  height: 300px;
  overflow: hidden;
  position: relative;
  * {
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: white;
      border-radius: 3px;
      border: 1px solid $insight-light-gray;
    }
    scrollbar-width: thin;
    scrollbar-color: white transparent;
  }
  .scroll {
    position: relative;
    max-height: 300px;
    overflow: auto;
    z-index: 2;
    &.addRightPadding {
      padding-right: 5px;
    }
  }
  .track{
    position: absolute;
    top: 0;
    right: 2px;
    width: 2px;
    background-color: $insight-yellow;
    height: 100%;
    border-radius: 2px;
    z-index: 1;
  }
}

.table {
  border-spacing: 0 12px !important;
  thead {
    tr {
      box-shadow: none;
      th {
        background-color: white;
        position: sticky;
        top: 0;
        padding-bottom: 5px;
        z-index: 1;
      }
    }
  }
  tr {
    box-shadow: 0px 4px 4px rgba(50, 75, 90, 0.02);
    td {
      background-color: $insight-bg-yellow !important;
      height: 49px;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      box-shadow: none !important;
      .wrapContent {
        padding: 0 24px;
      }
    }
  }
}

.empty {
  padding: 100px 0;
}
