@import '../../../../../shared/static/styles/partials/variables';

.scroll {
    height: 50%;
    overflow: hidden;
    position: relative;
    * {
      ::-webkit-scrollbar {
        width: 6px;
      }
      ::-webkit-scrollbar-track {
        background: transparent;
      }
      ::-webkit-scrollbar-thumb {
        background-color: white;
        border-radius: 3px;
        border: 1px solid $insight-light-gray;
      }
      scrollbar-width: thin;
      scrollbar-color: white transparent;
    }
  
    .track {
      position: absolute;
      top: 0;
      right: 2px;
      width: 2px;
      background-color: $insight-yellow;
      height: 100%;
      border-radius: 2px;
      z-index: 1;
    }
}
  
.item {
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 4px 4px rgba($color: $insight-primary, $alpha: 0.02);
    padding: 16px;
    max-height: 75px;

    .header-row {
        box-shadow: none;
        .header-col {
            background-color: white;
            position: sticky;
            top: 0;
            padding-bottom: 5px;
            z-index: 1;
        }
    }
}

.empty {
    padding: 50px 0;
}