@import '../../../../../shared/static/styles/partials/variables';

.surveysDraftContainer {
  margin-top: 0 !important;
  padding-top: 20px !important;
}

.search {
  border: none;
  border-bottom: 2px solid $insight-light-gray;
  background-color: transparent;
  width: 100%;
  max-width: 464px;
  padding: 8px 16px;
  color: $insight-gray;
  font-weight: 600;
  margin-bottom: 40px;
  &::placeholder {
    font-size: 11px;
    line-height: 15px;
    font-weight: 700;
    text-transform: uppercase;
    color: $insight-light-gray;
  }
  &:hover {
    border-bottom-color: $insight-yellow;
  }
  &:focus {
    border-bottom-color: $insight-secondary;
  }
}
