@import '../../../../../shared/static/styles/partials/variables';

.face {
  img {
    border-radius: 32px;
    width: 32px;
    margin-right: 10px;
  }
}

.countColor {
  color: #68c7c2;
}

.table{
  border-spacing: 0 19px !important;
  tr {
    td{
      height: 75px;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      padding-left: 0px !important;
      padding-right: 0px !important;
      .wrapContent {
        padding: 0 24px;
      }
      div {
        height: 20px;
        width: 100%;
      }
    }
  }
}

.fitContent {
  width: fit-content;
}
