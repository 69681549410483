@import '../../../../static/styles/partials/variables';

.noTransform {
  text-transform: none !important;
}

.hintColor {
  color: $insight-gray;
}

.popperButton {
  font-weight: 600 !important;
  &:focus {
    background-color: transparent !important;
    box-shadow: none !important;
  }
  &:active {
    background-color: transparent !important;
    box-shadow: none !important;
  }
}

.adjustTooltipBody {
  padding-right: 12px;
}
