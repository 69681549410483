@import '../../../../../../shared/static/styles/partials/variables';

.header{
  background-color: $insight-light-yellow;
  padding: 40px 30px 0;
  img{
    width: 100%;
    max-width: 167px;
    margin-top: -17px;
  }
  h3{
    margin-bottom: 20px;
  }
}