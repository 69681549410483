.login {
  &-page {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-container {
    max-width: 400px;
    width: 90%;
    text-align: center;
    .logo {
      height: 80px;
      width: auto;
      margin-bottom: 24px;
    }
    .login-form {
      width: 100%;
      text-align: left;
      padding: 32px;
      position: relative;
      .spinner-container {
        position: absolute;
        background: #ffffff;
        border-radius: 4px;
        top: 0px;
        left: 4px;
        bottom: 4px;
        right: 4px;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .text-input {
        width: 100%;
      }
      .btn {
        margin: 0 auto;
        margin-bottom: -52px;
        box-shadow: none;
        overflow: hidden;
        position: relative;
        .submitting {
          position: absolute;
          background: #ffffff;
          border-radius: 4px;
          top: -2px;
          left: -2px;
          bottom: -2px;
          right: -2px;
          z-index: 2;
          border-radius: 100px;
        }
        &:disabled {
          opacity: 1;
        }
      }
    }
  }
}
