@import "../../../../../shared/static/styles/partials/variables";

.actions {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  min-width: 180px;

  button {
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 10px;
    font-weight: 700;
    line-height: 15px;
    color: $insight-gray;
    text-transform: uppercase;
    transition: color 0.25s ease-in-out;
    padding: 0px 5px;
    i {
      color: $insight-primary;
      margin-bottom: 6px;
      transition: color 0.25s ease-in-out;
    }
    
    &:hover, &:focus {
      color: $insight-secondary;
      i {
        color: $insight-secondary;
      }
    }
    &:disabled {
      pointer-events: none !important;
      cursor: default !important;
      opacity: 0.6;
    }
  }
}

.search {
  border: none;
  border-bottom: 2px solid $insight-light-gray;
  background-color: transparent;
  width: 75%;
  padding: 8px 16px;
  color: $insight-gray;
  font-weight: 600;
  margin-bottom: 46px;
  &::placeholder {
    font-size: 11px;
    line-height: 15px;
    font-weight: 700;
    text-transform: uppercase;
    color: $insight-light-gray;
  }
  &:hover {
    border-bottom-color: $insight-yellow;
  }
  &:focus {
    border-bottom-color: $insight-secondary;
  }
}

.marginBottom {
  margin-bottom: 72px !important;
}
