@import '../../../../../shared/static/styles/partials/variables';

.circleLoading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border: 0px solid $insight-secondary;
    border-radius: 50%;
    margin-top: 30px;
    margin-bottom: 30px;
    i {
      color: #fff;
    }
  }