.overlay {
    position: absolute;
    background-color: transparentize($color: white, $amount: 0.5);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}