.empty {
  padding-bottom: 100px;
  img {
    width: 212px;
    display: block;
    margin: 0 auto 50px;
  }
  p {
    margin-top: 30px;
  }
}
