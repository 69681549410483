@import '../../../../shared/static/styles/partials/variables';

.wrap {
    height: fit-content;
    overflow: hidden;
    position: relative;
    * {
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background-color: white;
        border-radius: 3px;
        border: 1px solid $insight-light-gray;
      }
      scrollbar-width: thin;
      scrollbar-color: white transparent;
    }
    .scroll {
      position: relative;
      max-height: 200px;
      overflow-y: auto;
      overflow-x: hidden;
      z-index: 2;
      padding-right: 30px;
    }
    .track{
      position: absolute;
      top: 0;
      right: 2px;
      width: 2px;
      background-color: $insight-yellow;
      height: 100%;
      border-radius: 2px;
      z-index: 1;
    }
}

.item {
    color: $insight-gray;
    padding: 5px 20px 20px 0px;
    margin: 0px 0px 25px 0px;
    border-bottom: 1px solid $insight-light-gray;
    &:last-of-type {
        border-bottom: none;
    }
    @media screen and (min-width: 1025px) {
        &:hover {
            cursor: pointer !important;
            color: $insight-secondary !important;
        }
    }
    &.activePartner {
      color: $insight-secondary !important;
    }
    .activeIcon {
      color: $insight-secondary !important;
    }
    &.noHover {
        @media screen and (min-width: 1025px) {
            &:hover {
                cursor: default !important;
                color: $insight-gray !important;
            }
        }
    }
}