@import '../../../../../../../../shared/static/styles/partials/variables';

.checkboxLabel {
  text-transform: none !important;
  width: fit-content;
}

.fileInput {
  width: auto;
  max-width: 287px;
  margin-top: 18px;
  label {
    width: 118px;
    display: flex !important;
    align-items: center;
    margin: auto;
    padding: 0 16px;
    justify-content: center;
    position: relative;
    margin-bottom: 11px;
    i {
      margin-right: 0px;
    }
  }
  label:focus-within {
    /* When the visually hidden child input has focus, style the parent. */
    border: 1px solid $insight-gray !important;
  }
  input {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap; /* 1 */
    clip-path: inset(50%);
    border: 0;
  }
}

.pictureContainer {
  max-width: 210px !important;
}

.frame {
  width: auto;
  max-width: 128px;
  height: 128px;
  background-color: $insight-bg-yellow;
  border-radius: 4px;
  border: 1px solid $insight-light-gray;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 0 auto;
  .picture {
    width: 100%;
    height: auto;
    &.placeholder {
      max-width: 35px;
      max-height: 32px;
    }
  }
}
