.buttonContainer {
  margin-top: 178px;
  display: flex;
  justify-content: flex-end;
}

.image {
  padding-left: 38px !important;
  padding-bottom: 126px;
  padding-top: 30px;
  padding-right: 51px !important;
  img {
    width: 100%;
    max-width: 260px;
    min-width: 260px;
  }
}

.fitContent {
  width: fit-content;
}
