@import '../../../../../../../shared/static/styles/partials/variables';

.groupTag {
  display: flex;
  align-items: center;
  height: 30px;
  padding: 6px 20px;
  border-radius: 20px;
  background-color: white;
  border: 1px solid $insight-primary;
  font-size: 12px;
  color: $insight-gray;
  font-weight: 500;
  margin-right: 10px;
  margin-bottom: 12px;
  line-height: 1;
}
