.line-number {
  border-radius: 50%;
  width: 23px;
  height: 23px;
  display: block;
  line-height: 24px;
  text-align: center;
  border: 1px solid $insight-secondary;
}
.ds-collapse {
  .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 300ms ease;
  }
}
.collapsible {
  .check {
    background-color: $insight-secondary;
    @extend .line-number;
    i {
      font-size: 14px;
      color: white;
    }
  }
  .number {
    @extend .line-number;
    font-size: 14px;
    width: 25px;
    height: 25px;
    color: $insight-secondary;
    font-weight: 600;
  }
  .collapse-header {
    background-color: white;
    border-top: 1px solid $insight-light-gray;
    height: 80px;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
    cursor: pointer;
  }
  .collapse-body {
    background-color: $insight-lighter;
    border-top: 1px solid $insight-light-gray;
    padding: 30px 25px;
    .check {
      background-color: $insight-secondary;
      border-radius: 50%;
      width: 23px;
      height: 23px;
      display: block;
      line-height: 24px;
      text-align: center;
      i {
        font-size: 14px;
        color: white;
      }
    }
  }
}
