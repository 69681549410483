@import '../../../../../shared/static/styles/partials/variables';

.circle{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border: 3px solid $insight-error;
  border-radius: 50%;
  &::after{
    content: '';
    width: 24px;
    height: 3px;
    background-color: $insight-error;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -1px;
    margin-left: -12px;
    transform: rotate(-45deg);
  }
  &::before{
    content: '';
    width: 24px;
    height: 3px;
    background-color: $insight-error;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -12px;
    margin-top: -1px;
    transform: rotate(45deg);
  }
}