@import '../../../static/styles/partials/variables';

.phoneInputCustom {
  position: relative;
  input[type='tel'] {
    display: block;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid $insight-bg-yellow;
    background-color: $insight-bg-yellow;
    border-radius: 4px;
    height: 46px;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    background-clip: padding-box;
    line-height: 18px;
    color: $insight-primary;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    padding: 0.375rem 0.75rem 0.375rem 70px;
    &:hover {
      border-color: $insight-yellow;
    }
    &:disabled {
      opacity: 0.25;
      pointer-events: none;
    }
    &:read-only {
      border: 1px solid $insight-bg-yellow;
      background-color: $insight-bg-yellow;
      &:hover {
        border-color: $insight-bg-yellow;
      }
    }
    &:focus {
      border-color: $insight-secondary;
      color: $insight-gray;
      box-shadow: none;
    }
    &::placeholder {
      color: $insight-light-gray;
      opacity: 0.7;
      text-transform: uppercase;
    }
  }
  &.error {
    input {
      border-color: $insight-orange;
    }
  }
}

.countryInput {
  .menu {
    top: 100%;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 11px rgba(0, 0, 0, 0.1);
    margin-bottom: 8px;
    margin-top: 8px;
    position: absolute;
    width: 100%;
    z-index: 2;
    box-sizing: border-box;
  }
  .trigger {
    background-color: transparent;
    border: none;
    position: absolute;
    top: 0;
    height: 45px;
    display: grid;
    grid: auto / 18px 20px;
    gap: 8px;
    justify-content: center;
    align-items: center;
    padding: 0 0.75rem;

    &.open {
      .arrow {
        fill: $insight-primary;
      }
    }

    .arrow {
      fill: $insight-gray;
    }

    svg {
      color: $insight-primary;
    }
  }
}
