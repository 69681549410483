.panel {
  width: 100%;
  height: 100%;
}

.scrollContainer {
  max-height: calc(100vh - 167px);
}

.reduceOpacity {
  opacity: 0.4 !important;
  z-index: 500 !important;
  pointer-events: none !important;
}

.checkbox {
  min-width: 16px;
}
