@import '../../../shared/static/styles/partials/variables';

.previewContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 360px;
  margin: 0 auto;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.staticFormControl {
  pointer-events: none !important;
  text-transform: none !important;
  display: flex;
  align-items: flex-start;
  padding: 0 16px;
}

.noPointerEvents {
  pointer-events: none !important;
}

.header {
  padding: 16px 32px;
  background-color: $insight-primary;

  .newMessage {
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
    color: $insight-mild-yellow;
    margin-bottom: 10px;
  }

  .orgName {
    overflow-wrap: break-word;
    word-break: break-all;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    white-space: pre-wrap;
  }
}

.warning {
  background-color: $insight-mild-yellow;
  color: $insight-primary;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  padding: 20px 32px;
}

.body {
  padding: 20px 32px 32px;
  background-color: white;

  .title {
    overflow-wrap: break-word;
    word-break: break-all;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    white-space: pre-wrap;
  }

  .message {
    min-height: 145px;
    overflow-wrap: break-word;
    word-break: break-all;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    white-space: pre-wrap;
  }
}

.button {
  width: 100%;
  max-width: 132px;
  margin: 24px auto;
  justify-content: center;
  max-height: 36px;
}
