@import '../../../../../shared/static/styles/partials/variables';

.checkBoxLabel {
  width: fit-content;
  text-transform: none !important;
}

.bottomWrapper {
  padding-top: 51px;
}

.fileInput {
  width: auto;
  max-width: 287px;
  margin-top: 18px;
  label {
    width: 171px;
    display: flex !important;
    align-items: center;
    margin: auto;
    padding: 0;
    justify-content: center;
    position: relative;
    margin-bottom: 11px;
  }
  label:focus-within {
    /* When the visually hidden child input has focus, style the parent. */
    border: 1px solid $insight-primary !important;
  }
  input {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap; /* 1 */
    clip-path: inset(50%);
    border: 0;
  }
}

.wrapper {
  padding: 8px;

  .frame {
    width: auto;
    max-width: 287px;
    height: 287px;
    background-color: $insight-bg-yellow;
    border-radius: 4px;
    border: 1px solid $insight-light-gray;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    .picture {
      width: 100%;
      height: auto;
      &.placeholder {
        max-width: 78px;
        max-height: 78px;
      }
    }
  }
}
