@import '../../../shared/static/styles/partials/variables';

.container {
    margin: 120px;
    max-height: 100%;
    padding: 40px;
    @media screen and (max-width: 1024px) {
      margin: 80px;
    }
}

.logo {
    width: 50px;
    margin-right: 20px;
}

.errorBody {
    border-bottom: solid 1px $insight-light-gray;
    padding: 35px;
}

.componentStack {
    white-space: pre-line;
}