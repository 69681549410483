@import '../../static/styles/partials/variables';

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border: 3px solid $insight-error;
  border-radius: 50%;
  position: relative;
  &::after {
    content: '';
    width: 24px;
    height: 3px;
    background-color: $insight-error;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -1px;
    margin-left: -12px;
    transform: rotate(-45deg);
  }
  &::before {
    content: '';
    width: 24px;
    height: 3px;
    background-color: $insight-error;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -12px;
    margin-top: -1px;
    transform: rotate(45deg);
  }
}

.frame {
  width: 100px;
  height: 100%;
  min-width: 100px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 18px;
  img {
    width: 100%;
    vertical-align: middle;
  }
}

.message {
  padding: 10px 18px 10px 0px;
  height: 100%;
  div {
    margin-bottom: 11px;
  }
}

.toast {
  max-width: 350px !important;
  padding-bottom: 23px;
  padding-top: 23px;
  box-sizing: border-box;
  position: fixed;
  z-index: 999999;
  bottom: 40px;
  right: 40px;
  button {
    background-color: transparent;
    border: none;
    position: absolute;
    right: 13px;
    top: -17px;
  }
}

.close {
  transform: translateX(10px);
  i {
    font-size: 14px;
    color: $insight-gray;
  }
}
