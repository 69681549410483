.tooltip-container {
  border: solid 1px $insight-light-gray !important;
  background: #ffffff !important;
  color: $insight-gray !important;
  position: relative;
  max-width: 400px;
  font-size: 14px !important;
  line-height: 21px;
  font-weight: 500;
  &.error {
    border: solid 1px $insight-orange !important;
    background: #ffffff !important;
    color: $insight-orange !important;
  }
  &.place-top {
    &:after,
    &:before {
      top: 100% !important;
      left: 50% !important;
      border: solid transparent !important;
      content: ' ';
      height: 0 !important;
      width: 0 !important;
      position: absolute !important;
      pointer-events: none !important;
    }
    &:after {
      border-color: rgba(255, 255, 255, 0) !important;
      border-top-color: #ffffff !important;
      border-width: 6px !important;
      margin-left: -6px !important;
    }
    &:before {
      border-color: rgba(219, 220, 225, 0) !important;
      border-top-color: #dbdce1 !important;
      border-width: 7px !important;
      margin-left: -7px !important;
    }
  }
  &.place-left {
    &:after,
    &:before {
      left: 100% !important;
      top: 50% !important;
      border: solid transparent !important;
      content: ' ';
      height: 0 !important;
      width: 0 !important;
      position: absolute !important;
      pointer-events: none !important;
    }

    &:after {
      border-color: rgba(255, 255, 255, 0) !important;
      border-left-color: #ffffff !important;
      border-width: 6px !important;
      margin-top: -6px !important;
    }
    &:before {
      border-color: rgba(219, 220, 225, 0) !important;
      border-left-color: #dbdce1 !important;
      border-width: 7px !important;
      margin-top: -7px !important;
    }
  }
  &.place-bottom {
    &:after,
    &:before {
      bottom: 100% !important;
      left: 50% !important;
      border: solid transparent !important;
      content: ' ';
      height: 0 !important;
      width: 0 !important;
      position: absolute !important;
      pointer-events: none !important;
    }

    &:after {
      border-color: rgba(255, 255, 255, 0) !important;
      border-bottom-color: #ffffff !important;
      border-width: 6px !important;
      margin-left: -6px !important;
    }
    &:before {
      border-color: rgba(219, 220, 225, 0) !important;
      border-bottom-color: #dbdce1 !important;
      border-width: 7px !important;
      margin-left: -7px !important;
    }
  }
  &.place-right {
    &:after,
    &:before {
      right: 100% !important;
      top: 50% !important;
      border: solid transparent !important;
      content: ' ' !important;
      height: 0 !important;
      width: 0 !important;
      position: absolute !important;
      pointer-events: none !important;
    }

    &:after {
      border-color: rgba(255, 255, 255, 0) !important;
      border-right-color: #ffffff !important;
      border-width: 6px !important;
      margin-top: -6px !important;
    }
    &:before {
      border-color: rgba(219, 220, 225, 0) !important;
      border-right-color: #dbdce1 !important;
      border-width: 7px !important;
      margin-top: -7px !important;
    }
  }

  .tool-tip-label {
    display: block;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: $insight-gray;
  }
  .tool-tip-value {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: $insight-secondary;
  }
}

// Adding few styles to react-tooltip without the help of an additional class

.__react_component_tooltip {
  box-shadow: 0px 4px 4px 0px rgba(50, 75, 90, 0.02) !important;
  max-width: 300px;
  padding: 15px !important;
  font-size: 14px !important;
  line-height: 21px;
  font-weight: 500;
  border: 1px solid $insight-light-gray !important;
  color: $insight-gray !important;
  border-radius: 4px !important;
  &.bring-it-up {
    z-index: 10000;
  }
  &.break-words {
    word-break: break-all;
  }
  &.overflow-wrap {
    overflow-wrap: break-word;
    hyphens: auto;
  }
  &.width-auto {
    max-width: fit-content !important;
  }
  &.custom-menu-tooltip {
    padding: 32px !important;
    z-index: 10000 !important;
    word-break: break-all !important;
    overflow-wrap: break-word !important;
  }
  &.custom-tooltip-without-breaking-words {
    padding: 24px !important;
    z-index: 10000 !important;
  }
  &.nivo-tooltip {
    box-shadow: 0px 2px 4px rgba($color: #000000, $alpha: 0.08) !important;
    padding: 5px 10px !important;
    &::before {
      display: none !important;
    }
    &::after {
      display: none !important;
    }
  }
  &.place-right {
    &::before {
      border-left-color: $insight-light-gray !important;
      border-bottom-color: $insight-light-gray !important;
      border-width: 1px !important;
    }
  }
  &.place-top {
    &::before {
      border-bottom-color: $insight-light-gray !important;
      border-right-color: $insight-light-gray !important;
      border-width: 1px !important;
    }
  }
  &.place-bottom {
    &::before {
      border-top-color: $insight-light-gray !important;
      border-left-color: $insight-light-gray !important;
      border-width: 1px !important;
    }
  }
  &.place-left {
    &::before {
      border-top-color: $insight-light-gray !important;
      border-right-color: $insight-light-gray !important;
      border-width: 1px !important;
    }
  }

  &.error {
    border: 1px solid $insight-orange !important;
    color: $insight-orange !important;
    background-color: lighten($color: $insight-orange, $amount: 70);
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    &.place-right {
      &::before {
        display: none;
      }
    }
    &.place-top {
      &::before {
        border-bottom-color: $insight-error !important;
        border-right-color: $insight-error !important;
        border-width: 1px !important;
      }
    }
    &.place-bottom {
      &::before {
        display: none;
      }
    }
  }
  &.row-info {
    border: solid 1px $insight-secondary !important;
    &.place-bottom {
      &::before {
        border-top-color: $insight-secondary !important;
        border-left-color: $insight-secondary !important;
        border-width: 1px !important;
      }
    }
    &.place-left {
      &::before {
        border-top-color: $insight-secondary !important;
        border-right-color: $insight-secondary !important;
        border-width: 1px !important;
      }
    }
  }
  &.type-light {
    opacity: 0.95 !important;
  }
  &.place-left {
    &::after {
      display: none;
    }
    &::before {
      width: 16px;
      height: 16px;
      display: block;
      background: white;
      transform: rotate(45deg);
      border-top-width: 1px !important;
      border: 1px solid transparent;
      margin-top: -8px;
    }
  }
  &.place-right {
    &::after {
      display: none;
    }
    &::before {
      width: 16px;
      height: 16px;
      display: block;
      background: white;
      transform: rotate(45deg);
      border-top-width: 1px !important;
      border: 1px solid transparent;
      border-bottom-color: $insight-gray;
      border-left-color: $insight-gray;
      margin-top: -8px;
    }
  }
  &.place-top {
    &::after {
      display: none;
    }
    &::before {
      width: 16px;
      height: 16px;
      display: block;
      background: white;
      transform: rotate(45deg);
      border-top-width: 1px !important;
      border: 1px solid transparent;
      border-bottom-color: $insight-gray;
      border-right-color: $insight-gray;
      margin-left: -8px;
    }
  }
  &.place-bottom {
    &::after {
      display: none;
    }
    &::before {
      width: 16px;
      height: 16px;
      display: block;
      background: white;
      transform: rotate(45deg);
      border-top-width: 1px !important;
      border: 1px solid transparent;
      border-top-color: $insight-gray;
      border-left-color: $insight-gray;
      margin-left: -8px;
    }
  }
}

%popover-inner-placeholder {
  box-shadow: 0px 4px 4px 0px rgba(50, 75, 90, 0.02) !important;
  font-family: 'Montserrat', sans-serif;
  padding: 24px !important;
  font-size: 14px !important;
  line-height: 21px;
  font-weight: 500;
  border: 1px solid $insight-secondary !important;
  color: $insight-gray !important;
  border-radius: 4px !important;
  position: relative;
}

%animated-arrow {
  content: '';
  position: absolute;
  display: block !important;
  background-image: url('../../img/animations/arrow-animation.gif') !important;
  width: 30px !important;
  height: 40px !important;
  background-size: 30px !important;
  border: none !important;
  background-color: transparent !important;
}

.popover {
  font-family: 'Montserrat', sans-serif;
  &[x-out-of-boundaries='true'] {
    visibility: hidden !important;
    pointer-events: none !important;
  }
  &.break-words {
    word-break: break-all;
  }
  &.overflow-wrap {
    overflow-wrap: break-word;
    hyphens: auto;
  }
  &.break-words-with-overflow-wrap {
    word-break: break-all !important;
    overflow-wrap: break-word !important;
    hyphens: auto;
  }
  &.messaging-popper {
    border: none;
    background-color: transparent;
    max-width: 355px;
    z-index: 90;
    .popover-inner {
      @extend %popover-inner-placeholder;
      border: 1px solid $insight-secondary;
      padding: 15px 29px !important;
      background-color: white;
      transform: translateY(65px);
      &::before {
        @extend %animated-arrow;
        left: 50%;
        margin-left: -15px;
        top: -65px;
        height: 65px !important;
      }
    }
  }
  &.project-popper {
    border: none;
    background-color: transparent;
    max-width: 190px;
    z-index: 100;
    &.action-bar-popper {
      .popover-inner {
        transform: translateY(-50px);
      }
      @media (max-width: 1366px) {
        .popover-inner {
          transform: translate(50px, -50px);
        }
      }
    }
    &.facilitator-popper {
      .popover-inner {
        transform: translate(-6px, -20px);
      }
    }
    &.groups-popper {
      max-width: 226px;
      .popover-inner {
        padding: 15px 5px !important;
      }
    }
    .popover-inner {
      @extend %popover-inner-placeholder;
      border: 1px solid $insight-secondary;
      padding: 15px !important;
      background-color: white;
      text-align: center;
      transform: translateY(-20px);
      &::before {
        @extend %animated-arrow;
        transform: rotate(180deg);
        left: 50%;
        margin-left: -15px;
        bottom: -30px;
        height: 30px !important;
      }
    }
    .close-container {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
    }
  }
  &.existing-user-list {
    box-shadow: 0px 4px 4px 0px rgba(50, 75, 90, 0.02) !important;
    padding: 24px !important;
    font-size: 14px !important;
    line-height: 21px;
    font-weight: 500;
    border: 1px solid $insight-light-gray !important;
    color: $insight-gray !important;
    border-radius: 4px !important;
    .popover-body {
      padding: 0;
    }
    button {
      font-weight: 500 !important;
      &:focus {
        background-color: transparent !important;
        box-shadow: none !important;
      }
      &:active {
        background-color: transparent !important;
        box-shadow: none !important;
      }
    }
  }
  &.invite-user-permission {
    border: 1px solid $insight-secondary !important;
    padding: 23px 32px !important;
    padding-right: 20px !important;
    .arrow {
      border-top-color: $insight-secondary !important;
      border-left-color: $insight-secondary !important;
    }
    button {
      font-weight: 600 !important;
      &:focus {
        background-color: transparent !important;
        box-shadow: none !important;
      }
      &:active {
        background-color: transparent !important;
        box-shadow: none !important;
      }
    }
  }
  &.go-back-helper {
    border: none;
    max-width: 200px;
    background-color: transparent;
    .popover-inner {
      @extend %popover-inner-placeholder;
      border: 1px solid $insight-secondary;
      padding: 15px !important;
      background-color: white;
      transform: translateX(-45px);
      text-align: center;
      &::before {
        @extend %animated-arrow;
        transform: rotate(90deg);
        right: -35px !important;
        top: 50%;
        margin-top: -20px !important;
      }
      .go-back {
        border: none;
        background: transparent;
        border-bottom: 1px solid $insight-gray;
        color: $insight-gray;
        line-height: 1;
        padding: 0;
        font-weight: 500;
      }
    }
    .close-container {
      position: absolute;
      bottom: 0.5rem;
      right: 0.5rem;
    }
  }
  &.edit-helper {
    border: none;
    max-width: 200px;
    background-color: transparent;
    &.modal-view {
      margin-left: 0% !important;
    }
    .popover-inner {
      @extend %popover-inner-placeholder;
      text-align: center;
      border: 1px solid $insight-secondary;
      padding: 15px !important;
      background-color: white;
      transform: translateY(40px);
      &::before {
        @extend %animated-arrow;
        left: 50% !important;
        margin-top: 0 !important;
        top: -40px !important;
        margin-left: -15px !important;
      }
    }
    .close-container {
      position: absolute;
      bottom: 0.5rem;
      right: 0.5rem;
    }
  }
  &.getting-started {
    max-width: 300px;
    width: 218px;
    border: none;
    left: 40px !important;
    margin-right: -40px !important;
    top: -15px !important;
    display: block !important;

    .popover-inner {
      @extend %popover-inner-placeholder;
      &::before {
        @extend %animated-arrow;
        transform: rotate(-90deg) !important;
        left: -36px !important;
        margin-top: 0 !important;
        top: 10px !important;
        margin-left: 0 !important;
      }
    }
    .close-container {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
    }
    .arrow {
      display: none;
    }
  }
  &.currency-toggle-popover {
    max-width: 300px;
    width: 218px;
    border: none;
    left: 40px !important;
    margin-right: -40px !important;
    top: -55px !important;
    display: block !important;
    z-index: 100;
    .popover-inner {
      @extend %popover-inner-placeholder;
      &::before {
        @extend %animated-arrow;
        transform: rotate(-90deg) !important;
        left: -36px !important;
        margin-top: 0 !important;
        top: 55px !important;
        margin-left: 0 !important;
      }
    }
    .close-container {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
    }
    .arrow {
      display: none;
    }
  }
  &.survey-popper {
    border: none;
    background-color: transparent;
    max-width: 190px;
    z-index: 90;

    &.recipient-popper {
      max-width: none;

      @media screen and (min-width: 1280px) {
        width: 255px;
        br {
          display: block;
        }
      }
      @media screen and (min-width: 1600px) {
        width: 300px;
        br {
          display: none;
        }
      }

      .popover-inner {
        transform: translateY(-70px);
        &::before {
          left: 15px !important;
          margin-left: 0 !important;
          bottom: -50px;
          height: 50px !important;
        }
        &::after {
          right: 15px;
          @extend %animated-arrow;
          transform: rotate(180deg);
          bottom: -50px;
          height: 50px !important;
        }
      }
    }

    &.select-recipients {
      margin-left: -120px;
      .popover-inner {
        transform: translate(0px, 0px);
      }
    }

    &.action-bar-popper {
      .popover-inner {
        transform: translateY(-50px);
      }
      @media (max-width: 1366px) {
        .popover-inner {
          transform: translate(50px, -50px);
        }
      }
    }
    .popover-inner {
      @extend %popover-inner-placeholder;
      border: 1px solid $insight-secondary;
      padding: 15px !important;
      background-color: white;
      text-align: center;
      transform: translateY(-30px);
      &::before {
        @extend %animated-arrow;
        transform: rotate(180deg);
        left: 50%;
        margin-left: -15px;
        bottom: -30px;
        height: 30px !important;
      }
    }

    .close-container {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
    }
  }
}
