@import '../../../../../shared/static/styles/partials/variables';

.anim {
  max-height: 191px;
  max-width: 191px;
}

.spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  top: 0;
  left: 0;
  z-index: 3;
}

.radioFitContent {
  width: fit-content;
}

.radioActive {
  color: #324b5a !important;
}

.overlay {
  position: absolute;
  background-color: transparentize($color: white, $amount: 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.confirmation {
  border: 1px solid $insight-orange;
  z-index: 1;
  position: relative;
  border-radius: 4px;
  width: 309px;
  padding: 31px 26px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  div, button:first-of-type {
    margin-bottom: 24px;
  }
  &.deleteConfirmation {
    width: 517px;
    padding: 31px 80px;
  }
}
