@import '../../../../../shared/static/styles/partials/variables';

.scroll{
  height: calc(100vh - 95px); // 95px = Main header, 112px = 56px * 2 top/bottom padding from .content-container 
  overflow: hidden;
  position: relative;
  *{
    ::-webkit-scrollbar {
      width: 6px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background-color: white;
      border-radius: 3px;
      border: 1px solid $insight-light-gray;
    }
    scrollbar-width: thin;
    scrollbar-color: white transparent;
  }

  .track{
    position: absolute;
    top: 0;
    right: 2px;
    width: 2px;
    background-color: $insight-yellow;
    height: 100%;
    border-radius: 2px;
    z-index: 1;
  }
}


.empty{
  padding-top: 68px;
  h2{
    margin-bottom: 65px;
  }
  img{
    width: 212px;
    display: block;
    margin: 0 auto 50px;
  }
  
  p{
    margin-top: 30px;
  }
}