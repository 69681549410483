@import '../../../static/styles/partials/variables';

.bar {
  display: block; 
  background-color: $insight-light-gray;
  margin-bottom: 1rem;
  &.big {
    background-color: $insight-gray;
    height: 12px;
    opacity: 0.5;
    border-radius: 4px;
  }

  &.small {
    height: 7px;
    border-radius: 2px;
  }

  &.tiny{
    height: 4px;
    border-radius: 1.5px;
    margin-top: 60px;
    margin-bottom: 60px;
  }
}
