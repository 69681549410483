.insight-tab{
  ul{
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    padding: 0;
    li{
      list-style: none;
      display: block;
      padding-right: 20px;
      padding-left: 20px;
      a,button{
        height: 30px;
        box-shadow: none;
        background-color: white;
        border-radius: 0;
        border: none !important;
        padding-left: 0;
        padding-right: 0;
        position: relative;
        color: $insight-primary !important;
        display: flex;
        align-items: baseline;
        overflow: hidden;
        font-size: 14px;
        text-transform: capitalize !important;
        
        &::after{
          content: '';
          position: absolute;
          height: 2px;
          width: 100%;
          bottom: 0;
          left: 0;
          border-radius: 8px;
          background-color: $insight-secondary;
          transform: translateY(3px);
          transition: transform 0.2s ease;
        }

        &:hover{
          border: none !important;
          box-shadow: none;
          &::after{
            transform: translateY(0px);
          }
        }

        &:focus{
          box-shadow: none !important;
        }
        &.bg-transparent{
          &.active {
            &:focus {
              background-color: transparent !important;
            }
          }
          &:focus{
            background-color: transparent !important;
          }
        }
        &.active{
          &:not(.bg-transparent){
            background-color: white !important;
          }
          background-color: transparent !important;
          color: $insight-secondary !important;
          &::after {
            transform: translateY(0px);
          }
        }
      }
    }
  }
}