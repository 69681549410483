@import '../../../../../../shared/static/styles/partials/variables';

.item {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba($color: $insight-primary, $alpha: 0.02);
  border: 1px solid transparent;
  padding: 30px 24px;
  max-height: 600px;
  transition: border 0.36s ease;

  &:hover {
    border: 1px solid $insight-yellow;
  }

  &.collapsed {
    height: 600px;
    margin-bottom: 0px;
  }

  p {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    color: $insight-primary;
    margin-bottom: 0;
    width: auto;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .date {
    min-width: 175px;
  }

  .name {
    @media screen and (min-width: 1300px) {
      max-width: 170px !important;
    }
    @media screen and (min-width: 1400px) {
      max-width: 230px !important;
    }
  }

  .actions {
    min-width: 350px;
    button {
      margin: 0 0.5rem;
    }
  }

  h4 {
    span {
      display: inline-block;
      vertical-align: baseline;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .card {
    width: 180px;
    max-height: 178px;
    overflow: hidden;
    margin-right: 3px;
    margin-left: 3px;
    height: 100%;
    border-color: $insight-light-gray;
    box-shadow: 0px 4px 4px rgba($color: $insight-primary, $alpha: 0.02);

    &.warn {
      border-color: $insight-orange;
      .footer {
        border-color: $insight-orange;
      }
      h3 {
        i {
          display: inline;
        }
      }
    }

    h3 {
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 5; /* number of lines to show */
      -webkit-box-orient: vertical;
      i {
        display: none;
        font-size: 20px;
        margin-left: 8px;
      }
    }

    .plus {
      text-decoration: none;
      transition: color 0.36s ease;
      &.disabled {
        opacity: 0.25;
      }
      &:hover {
        color: $insight-primary !important;
      }
      &:focus-visible {
        outline: none !important;
        color: $insight-primary !important;
      }
    }

    .footer {
      padding: 14px;
      background-color: white;
      border-color: $insight-light-gray;
      min-height: 61px;
      display: flex;
      align-items: center;

      img {
        max-height: 32px;
      }

      .frame {
        display: flex;
        justify-content: flex-start;
        width: auto;
        max-width: 120px;
        overflow: hidden;
        white-space: nowrap;

        .face {
          width: 32px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          border-radius: 16px;
          margin-right: 8px;
          img {
            height: 32px;
            width: 32px;
            object-fit: cover;
          }
        }
      }

      .view {
        min-width: 32px;
        height: 32px;
        font-weight: 700;
        font-size: 8px;
        line-height: 8px;
        padding: 9px 0 6px;
        border-radius: 16px;
        text-align: center;
        span {
          width: 100%;
        }
      }
    }
  }

  .disabled {
    pointer-events: none !important;
  }
}

.transformUpper {
  text-transform: uppercase;
}
