@import '../../../../../../shared/static/styles/partials/variables';

.item {
  background-color: $insight-bg-yellow;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba($color: $insight-primary, $alpha: 0.02);
  padding: 16px;
  max-height: 75px;
}

.truncate {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 60px;
}
