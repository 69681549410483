.insight-table-container {
  width: 100%;
  padding-top: 64px;
  position: relative;
  padding-bottom: 100px;
  min-height: 600px;
}
.insight-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 12px;
  thead {
    th {
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      padding: 0px 24px;
      text-transform: uppercase;
      color: $insight-gray;
      vertical-align: text-top;
      .select-inline {
        margin-left: 5px;
        &::after {
          color: $insight-gray;
        }
        select {
          color: $insight-secondary;
          font-weight: 700;
        }
      }
      .table-label {
        display: inline-flex;
        align-items: flex-start;
        line-height: 18px;
        .help {
          margin-left: 4px;
          height: 20px;
        }
        .icon-chevron-down,
        .icon-chevron-up {
          font-size: 12px;
          margin-top: 4px;
          margin-left: 4px;
        }
      }
    }
  }
  tr {
    border-radius: 4px;
    td {
      &.truncate-long-words {
        &.shorten-length {
          .table-content {
            max-width: 100px;
          }
        }
        &.extend-length {
          .table-content {
            max-width: 340px;
          }
        }
        .table-content {
          max-width: 200px;
          overflow: hidden !important;
          &.extend-length {
            max-width: 340px;
          }
        }
      }
      &.ml-auto {
        .table-content {
          margin-left: auto;
        }
      }
      padding: 20px 24px;
      font-weight: 600;
      font-size: 11px;
      line-height: 20px;
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(50, 75, 90, 0.02);
      vertical-align: middle;
      border: solid 1px transparent;
      transition: border-color 0.3s ease-in-out,
        background-color 0.3s ease-in-out;
      &:last-of-type {
        border-radius: 0px 4px 4px 0px;
      }
      &:first-of-type {
        &:not(.no-bg) {
          border-radius: 4px 0px 0px 4px;
        }
      }
      &.no-bg {
        background: none !important;
        border: none !important;
        box-shadow: none !important;
        & + td {
          border-radius: 4px 0px 0px 4px;
        }
      }
      &.checkbox-td {
        padding-right: 24px;
        padding-left: 0px;
        width: 35px;
      }
      .wrap-content {
        max-width: fit-content;
        display: block;
        margin: 0 auto;
        &.left-align {
          margin-left: 0px;
        }
      }
      .table-content {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    @media screen and (min-width: 1025px) {
      &:hover {
        td {
          border-top: solid 1px $insight-yellow;
          border-bottom: solid 1px $insight-yellow;
          &:last-of-type {
            border-right: solid 1px $insight-yellow;
          }
          &:first-of-type {
            border-left: solid 1px $insight-yellow;
          }
          &.no-bg {
            border: none;
            + td {
              border-left: solid 1px $insight-yellow;
            }
          }
        }
      }
      &.row-selected {
        td {
          background-color: $insight-mild-yellow;
        }
      }
      &.row-deactivated {
        td {
          // background-color: #E8E8E8; // TODO:
          background-color: rgba($color: $insight-gray, $alpha: 0.15);
          // opacity: 0.15;
        }
      }
    }
  }
  .facilitator {
    display: flex;
    align-items: center;
    .avatar {
      width: 24px;
      height: 24px;
      border-radius: 100%;
      background: #c4c4c4;
      margin-right: 15px;
    }
  }
  @media screen and (max-width: 1024px) {
    display: block;
    thead {
      display: none;
    }
    tbody {
      display: block;
    }
    tr {
      display: block;
      height: 68px;
      overflow: hidden;
      margin-bottom: 12px;
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(50, 75, 90, 0.02);
      position: relative;
      td {
        display: flex;
        align-items: center;
        background: none;
        box-shadow: none;
        &:before {
          content: attr(data-title);
          margin-right: 10px;
          font-weight: 700;
          font-size: 10px;
          line-height: 20px;
          text-transform: uppercase;
          color: $insight-gray;
        }
        &.checkbox-td {
          position: absolute;
          top: 24px;
          left: 15px;
          padding: 0px;
          &:before {
            display: none;
          }
          & + td {
            padding-left: 55px;
          }
        }
      }
      &:hover {
        height: auto;
        border: solid 1px $insight-yellow;
      }
    }
  }
}

.content-table {
  width: 100%;
  th {
    padding-bottom: 25px;
    vertical-align: top;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: $insight-primary;
    &.value {
      color: $insight-secondary;
      text-align: right;
    }
  }
  td {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: $insight-gray;
    padding-bottom: 16px;
    &.value {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: right;
    }
  }
  th,
  td {
    .help {
      position: relative;
      display: inline-block;
      height: 16px;
      width: 16px;
      i {
        position: absolute;
        width: 16px;
        height: 16px;
        top: 4px;
      }
    }
  }
}

.insight-table-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .pagination-label {
    font-weight: bold;
    font-size: 10px;
    line-height: 20px;
    text-transform: uppercase;
    color: $insight-light-gray;
    display: flex;
    align-items: center;
    &.align-right {
      justify-content: flex-end;
    }
    .select-inline {
      margin-left: 5px;
      &::after {
        color: $insight-gray;
      }
      select {
        color: $insight-secondary;
        font-weight: 700;
      }
    }
  }
  .pagination-col {
    flex: 1;
    @media screen and (min-width: 768px) {
      &:first-of-type,
      &:last-of-type {
        flex: 0 1 200px;
        max-width: 200px;
      }
    }
  }
  .pagination-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 11px;
    line-height: 20px;
    color: $insight-light-gray;
    > * {
      margin: 0px 4px;
    }
  }
  .btn-pagination {
    @include button-variant(
      #ffffff,
      $insight-light-gray,
      rgba($insight-light-gray, 0.2)
    );
    box-shadow: 0px 2px 0px rgba(219, 220, 225, 0.32);
    font-weight: 600;
    font-size: 11px;
    line-height: 20px;
    color: $insight-gray;
    border-radius: 4px;
    padding: 6px 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-style: solid;
    border-width: 1px;
    &.small {
      padding: 0px;
      height: 24px;
      width: 24px;
    }
    &.active {
      color: #ffffff !important;
      border-color: $insight-secondary !important;
      background-color: $insight-secondary !important;
    }
    &.disabled {
      color: $insight-light-gray;
      i {
        color: $insight-light-gray;
      }
    }
    i {
      font-size: 12px;
    }
  }
}

.table-select-container {
  .table-select {
    &__control {
      border: none;
      box-sizing: border-box;
      box-shadow: none;
      background: none;
      border-radius: 0px;
      height: auto;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      width: 50px;
      &:hover {
        border-color: $insight-secondary !important;
      }
    }
    &__value-container {
      padding: 0px;
    }
    &__placeholder {
      color: $insight-gray;
    }
    &__single-value {
      color: $insight-secondary;
      font-size: 10px;
      line-height: 20px;
      font-weight: 700;
    }
    &__indicators {
    }
    &__indicator {
      padding: 0px;
      svg {
        fill: $insight-gray;
      }
    }
    &__indicator-separator {
      display: none;
    }
    .table-select__menu {
      background: #ffffff;
      border: 1px solid #dbdce1;
      box-sizing: border-box;
      box-shadow: 0px 2px 0px rgba(219, 220, 225, 0.4);
      .table-select__menu-list {
        .table-select__option {
        }
        .table-select__option--is-focused {
          background-color: $insight-secondary;
        }
      }
    }
  }
}

.backup-tag {
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 4px 8px;
  display: inline-block;
  &.blue {
    background: $insight-blue;
  }
  &.green {
    background: $insight-secondary;
    color: #ffffff;
  }
  &.yellow {
    background: $insight-yellow;
  }
  &.red {
    background: $insight-orange;
    color: #ffffff;
  }
  &.gray {
    background: $insight-light-gray;
  }
}

.selected-row-info {
  color: #ffffff;
  background: $insight-primary;
  box-shadow: 0px 4px 4px rgba(50, 75, 90, 0.02);
  border-radius: 4px;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  margin-top: 34px;
  margin-bottom: -30px;
  animation: row-loading 0.3s ease-in-out;
  animation-fill-mode: forwards;
  .row-col {
    display: flex;
    align-items: center;
    .col-text {
      & + .col-text {
        margin-left: 40px;
      }
    }
    .col-disable {
      color: #dbdce1;
    }
    .col-link {
      cursor: pointer;
      &:focus, :focus-visible {
        color: $insight-secondary;
      }
    }
    .btn {
      height: 30px;
      line-height: 1;
      @media (max-width: 1366px) {
        height: auto;
      }
      & + .btn {
        margin-left: 30px;
      }
    }
  }
  .assign-facilitator {
    background-color: #f8e9c5;
    i {
      color: $insight-gray;
    }
  }
}

@keyframes row-loading {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}
