@import '../../../../../../../shared/static/styles/partials/variables';

.customScroll {
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 3px;
    border: 1px solid $insight-light-gray;
  }
  scrollbar-width: thin;
  scrollbar-color: white transparent;
}
