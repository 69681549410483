.badge{
  height: 25px;
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
  padding: 3px 12px;
  display: inline-block;
  max-width: 100%;
  &-primary{
    color: white;
    background-color: $insight-primary;
  }
}