@import './variables';

.insight-alert {
  border-radius: 4px;
  border: 1px solid;
  padding: 12px 14px;
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  white-space: initial;
  &--success {
    background-color: rgba($color: $insight-secondary, $alpha: 0.07);
    border-color: $insight-secondary;
    color: $insight-secondary;
  }
}

.alert {
  button, a {
    display: inline;
    border: none;
    color: $insight-secondary;
    font-weight: 600;
    background-color: transparent;
    text-decoration: none;
    &:disabled {
      cursor: default;
      pointer-events: none;
      opacity: 0.5;
      text-decoration: none;
    }
    &:hover {
      color: #007bff;
      text-decoration: underline;
    }
  }
  font-size: 14px;
}
