html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Montserrat', sans-serif;
  color: $insight-primary;
  background-color: $insight-bg-yellow;
}

* {
  outline: none !important;
}

a {
  color: $insight-secondary;
  &:focus-visible {
    outline: 1px solid $insight-primary !important;
  }
}

div[role='button'] {
  &:focus-visible {
    outline: 1px solid $insight-primary !important;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.bg-primary {
  background-color: $insight-primary;
}

// What's overriding the original class?
.ins-bg-primary {
  background-color: $insight-primary;
}

.content-container {
  margin-top: 95px;
  margin-left: 160px;
  min-height: calc(100vh - 95px);
  padding: 56px 40px;
  @media screen and (max-width: 1024px) {
    margin-left: 80px;
  }
}

.block-container {
  z-index: 95;
}

.main-title, .widget-title {
  color: $insight-primary;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin: 0px;
}

.dashboard-row {
  & + & {
    margin-top: 0px;
  }
  .flex-col {
    display: flex;
    .flex-col-inner {
      flex: 1;
      display: flex;
      max-width: 100%;
    }
  }
}

.help {
  text-decoration: none !important;
  display: inline-flex;
  align-items: center;
  .icon-help {
    color: $insight-gray;
    font-size: 16px;
    &.sidebar-icon-help {
      color: $insight-light-gray;
      font-size: 12px;
    }
  }
}

.chart-placeholder {
  max-width: 100%;
  height: auto;
}

.btn-col-right {
  display: flex;
  align-items: center;
  & > * {
    margin-left: 24px;
  }
  @media screen and (min-width: 769px) {
    justify-content: flex-end;
    & > * {
      margin-left: 24px;
    }
  }
}


@each $color, $value in $insight-colors {
  .text-#{$color} {
    color: $value !important;
  }
  .color-#{$color} {
    color: $value !important;
  }
}


$i: 4;
@while $i < 41 {
  .margin-#{$i} {
    margin: #{$i}px;
  }
  .padding-#{$i} {
    padding: #{$i}px;
  }
  $sides: top, right, bottom, left;
  @each $side in $sides {
    .margin-#{$side}-#{$i} {
      margin-#{$side}: #{$i}px;
    }
    .padding-#{$side}-#{$i} {
      padding-#{$side}: #{$i}px;
    }
  }
  $i: $i + 4;
}

// Increasing zIndex to show the custom scroll track in project items list
.List{
  z-index: 2;
}

//Separate styles only for close button

.insight-close-button {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  color: $insight-gray;
  transition: background-color 0.36s ease;
  &.focus-visible, :focus, :focus-visible {
    background-color: rgba($color: $insight-secondary, $alpha: .3);
  }
  &:hover {
    background-color: rgba($color: $insight-yellow, $alpha: .15);
  }
  &:active {
    background-color: rgba($color: $insight-secondary, $alpha: .15);
  }
  &.enlarge-icon {
    img{
      width: 13px;
      height: 13px;
    }
  }
  img{
    width: 11px;
    height: 11px;
  }
}

//divider

.divider {
  border-color: $insight-light-gray;
  margin-top: 26px;
  margin-bottom: 26px;
}

// backdrop for drop ups

.backdrop {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: $insight-bg-yellow, $alpha: .8);
  z-index: 1;
}

// perfect scrollbar overrides

.scrollbar-container {
  padding-right: 3px;
  &:hover {
    .ps__rail-y {
      opacity: 1 !important;
    }
  }
  .ps__rail-y {
    background-color: $insight-yellow;
    width: 2px;
    right: 3px !important;
    &:hover {
      background-color: $insight-yellow;
      width: 2px;
    }
    .ps__thumb-y {
      background-color: white;
      background-color: white;
      border-radius: 3px;
      border: 1px solid $insight-light-gray;
      width: 6px;
      right: -2px;
    }
  }
}