.modal {
  .modal-dialog {
    &.no-rounded-corners{
      .modal-content{
        border-radius: 0 !important;
      }
    }
    &.no-border{
      border: none !important;
      .modal-content{
        border: none !important;
        box-shadow: 0px 8px 13px rgba($color: $insight-primary, $alpha: .12);
      }
    }
    .modal-content {
      border: 1px solid $insight-light-gray;
      box-sizing: border-box;
      box-shadow: 0px 2px 0px rgba(219, 220, 225, 0.4);
      border-radius: 4px;
      .modal-header {
        padding: 32px 0px;
        margin: 0px 32px;
        border-bottom-color: $insight-light-gray;
        &.increase-font{
          h5{
            font-size: 24px;
            line-height: 36px;
            width: 100%;
          }
        }
        .truncate {
          max-width: 75%;
          display: inline-block;
          vertical-align: top;
          &.shorten {
            max-width: 40%;
          }
        }
        &.truncate {
          h5 {
            white-space: nowrap;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        &.text-center{
          justify-content: center;
        }
        small{
          display: block;
          color: $insight-gray;
          font-size: 16px;
          line-height: 24px;
          font-weight: 600;
          margin-top: 5px;
        }
        h5 {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: $insight-primary;
        }
      }
      .modal-body {
        padding: 32px;
        small{
          font-weight: 500;
          a{
            color: $insight-secondary;
          }
        }
      }
      .modal-footer {
        border: none;
        justify-content: center;
        padding: 32px 0px;
        margin-top: -32px;
        .btn-primary {
          &:disabled {
            opacity: 1;
          }
        }
      }
    }
  }
}

.modal-backdrop {
  background-color: #fafaf5;
  &.show {
    opacity: 0.8;
  }
}

button.gm-ui-hover-effect {
  display: none !important;
  img {
    margin: 0px !important;
    width: 24px !important;
    height: 24px !important;
    display: none !important;
  }
}
