@import '../../../../../shared/static/styles/partials/variables';

.containerMargin {
  margin-top: 65px !important;
}

.tab {
  &:focus {
    background-color: transparent !important;
  }
}

.groupFiltersTagWrapper {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0.375rem 0;
}

.filtersPlaceholder {
  color: rgba($color: $insight-primary, $alpha: 0.22);
}


