.main-header {
  min-height: 95px;
  background-color: #ffffff;
  position: fixed;
  top: 0px;
  left: 160px;
  right: 0px;
  z-index: 100;
  border-bottom: solid 1px rgba(50, 75, 90, 0.05);
  box-shadow: 0px 2px 2px rgba(50, 75, 90, 0.02);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 40px;
  transform: translateY(0%);
  transition: transform 0.3s ease-in-out;
  @media screen and (max-width: 1200px) {
    padding: 0px 15px;
  }
  &.hide {
    transform: translateY(-100%);
    @media screen and (max-width: 1024px) {
      &:hover {
        transform: translateY(0%);
      }
    }
  }
  .main-title {
    margin-right: 40px;
    display: flex;
    align-items: center;
    i {
      margin-right: 40px;
      color: $insight-light-gray;
    }
    a {
      display: flex;
      align-items: center;
      color: inherit;
      text-decoration: none;
      i {
        color: $insight-gray;
      }
      &:focus-visible {
        color: $insight-secondary;
        outline: none !important;
        i {
          color: $insight-secondary;
        }
      }
      &.disabled-nav-link {
        opacity: 0.7;
        pointer-events: none;
      }
    }
    @media screen and (min-width: 1025px) and (max-width: 1400px) {
      display: none;
    }
  }
  .filters-btn {
    display: none;
    @media screen and (max-width: 1024px) {
      display: block;
    }
  }
  .header-col {
    display: flex;
    align-items: center;
    @media screen and (max-width: 1200px) {
      .header-btn {
        padding: 0px 15px;
        height: 40px;
      }
    }
  }
  .header-user {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    padding: 0;
    background: white;
    margin-left: 30px;
    border: none;
    background-image: url(../../img/project-placeholders/blank_avatar.svg);
    &:focus {
      box-shadow: 0px 0px 0px 1px rgba($color: $insight-secondary, $alpha: 0.5);
    }
    @media screen and (min-width: 1400px) and (max-width: 1440px) {
      margin-left: 30px;
    }
    @media screen and (min-width: 1025px) and (max-width: 1400px) {
      margin-left: 10px;
    }
    img {
      width: 32px;
      height: 30px;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  .header-form {
    display: flex;
    align-items: center;
    .select-group {
      margin-top: -15px;
      button.dropdown-btn {
        @media screen and (min-width: 1300px) {
          max-width: 110px;
          min-width: 110px;
        }
        @media screen and (min-width: 1500px) {
          max-width: 110px;
          min-width: 110px;
        }
      }
    }
    @media screen and (max-width: 1024px) {
      position: absolute;
      top: 90px;
      left: 0px;
      right: 0px;
      padding: 16px;
      background: #ffffff;
      border-bottom: solid 1px rgba(50, 75, 90, 0.05);
      box-shadow: 0px 2px 2px rgba(50, 75, 90, 0.02);
      flex-wrap: wrap;
      display: none;
      .select-group {
        margin-top: 0px;
        margin-bottom: 15px;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    left: 80px;
    &:hover {
      .filters-btn {
        color: #212529;
        background-color: rgba(219, 220, 225, 0.2);
        border-color: #bfc1ca;
      }
      .header-form {
        display: flex;
      }
    }
  }
  .clear-filters {
    background-color: transparent;
    border-radius: 50%;
    border: none;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 0px 0px 0px 16px;
    color: $insight-gray;
    transition: color 0.36s ease;
    text-align: left;
    &:hover {
      color: $insight-primary;
    }
    &:disabled {
      pointer-events: none;
      opacity: 0.4;
    }
  }
}
