.main-sidebar {
  width: 160px;
  background-color: $insight-primary;
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  z-index: 99;
  transition: width 0.2s ease-in-out;
  // .sidebar-section {
  //   // box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
  // }
  // adjustment: not scrolling to the backup status chart when the window height changed
  .scrollbar-container {
    height: calc(100% - 98px);
    @media screen and (min-width: 1600px) {
      height: calc(100% - 100px);
    }
  }
  .logo {
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
    .logo-img {
      overflow: hidden;
      height: 65px;
      transform: translateX(0px);
      transition: transform 0.2s ease-in-out, height 0.2s ease-in-out;
      &.disabled {
        pointer-events: none;
      }
    }
    img {
      height: 65px;
      width: auto;
    }
  }
  .main-navigation {
    padding-bottom: 30px;
    .sidebar-nav {
      margin: 0px;
      padding: 0px;
      list-style: none;
      padding: 25px 0px;
      li {
        &:not(:last-child) {
          margin-bottom: 40px;
          transition: margin-bottom 0.2s ease-in-out;
        }
        &.disabled {
          opacity: 0.7;
          pointer-events: none;
        }
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          color: $insight-light-gray;
          font-size: 14px;
          line-height: 21px;
          text-decoration: none;
          position: relative;
          font-weight: 600;
          i {
            font-size: 20px;
            margin-bottom: 8px;
            transition: font-size 0.2s ease-in-out;
          }
          span {
            transition: height 0.2s ease-in-out;
            height: 21px;
          }
          &.active {
            color: #ffffff;
            i {
              color: $insight-secondary;
            }
            &:before {
              content: '';
              position: absolute;
              top: 0px;
              left: 0px;
              bottom: 0px;
              width: 3px;
              background: $insight-secondary;
              border-radius: 0px 4px 4px 0px;
            }
          }
          &.disabled-nav-link {
            opacity: 0.7;
            pointer-events: none;
          }
        }
        .currency-text {
          @media screen and (min-width: 1025px) {
            display: flex;
            justify-content: center;
          }
          .currency-label {
            font-weight: 700;
            font-size: 10px;
            line-height: 21px;
            color: $insight-light-gray;
            &.disabled-label {
              opacity: 0.7;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    &:not(:hover) {
      width: 80px;
      .logo {
        height: 95px;
        .logo-img {
          height: 36px;
          transform: translateX(-18px);
        }
      }
      .main-navigation {
        .sidebar-nav {
          li {
            &:not(:last-child) {
              margin-bottom: 30px;
            }
            a {
              i {
                font-size: 30px;
              }
              span {
                overflow: hidden;
                height: 0px;
              }
            }
          }
        }
      }
    }
  }
  .backup-status {
    padding: 14px 16px;
    &.fixed-to-the-bottom {
      position: absolute;
      bottom: calc(700px - 20px - 100%);
      box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.16);
      left: 0;
      width: 100%;
      &.raise-me-up {
        bottom: calc(100% - 160px - 537px - 91px);
      }
    }
    .text {
      @media screen and (min-width: 1025px) {
        display: flex;
        justify-content: space-between;
      }
      margin-bottom: 10px;
      span {
        font-weight: bold;
        font-size: 10px;
        line-height: 21px;
        color: $insight-light-gray;
      }
      i {
        color: $insight-light-gray;
      }
      i:hover {
        cursor: pointer;
      }
      &.backup-refresh {
        margin-top: 25px;
      }
    }
  }
}

.sidebar-tooltip {
  font-size: 14px;
  font-weight: 500;
  color: $insight-gray;
  span {
    display: block;
    font-weight: 600;
  }
}

.sidebar-scroll-container {
  min-height: 740px;
  position: relative;
  &.reduce-my-height {
    min-height: 650px;
  }
}
