@import '../../../../../../shared/static/styles/partials/variables';

.card{
  transition: background-color 0.36s ease;
  height: auto !important;
  margin-bottom: 5px;
  &:hover{
    background-color: lighten($color: $insight-bg-yellow, $amount: 2);
  }
  &.active{
    border-color: $insight-secondary;
    background-color: $insight-bg-yellow;
    .title{
      p{
        color: $insight-secondary-dark;
      }
      .check{
        display: inline-block;
      }
    }
    img{
      opacity: 1;
    }
  }
  img{
    max-height: 58px;
    opacity: 0.45;
  }
  .title{
    p{
      margin-bottom: 0;
      margin-top: 0;
      color: $insight-gray;
    }
    .check{
      display: none;
      background-color: $insight-secondary;
      border-radius: 50%;
      width: 14px;
      height: 14px;
      line-height: 14px;
      text-align: center;
      margin-left: 8px;
      i{
        font-size: 10px;
        color: white;
      }
    }
  }
}