@import '../../../../../shared/static/styles/partials/variables';

.anim {
  max-height: 191px;
  max-width: 191px;
}

.overlay {
  position: absolute;
  background-color: transparentize($color: white, $amount: 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.confirmation {
  border: 1px solid $insight-orange;
  z-index: 5;
  position: relative;
  border-radius: 4px;
  width: 309px;
  padding: 31px 26px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  div,
  button:first-of-type {
    margin-bottom: 24px;
  }
}

.wrapper {
  border-top: 1px solid $insight-light-gray;
  margin-top: 32px;
  padding-top: 40px;
  min-height: 468px;
}
