.group-map-view-container {
  position: relative;
  margin-top: 40px;
  .map-no-data {
    position: absolute;
    z-index: 10;
    background: #ffffff;
    padding: 30px;
    border: solid 1px $insight-orange;
    color: $insight-gray;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    h4 {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
    }
  }
}

.map-view-container {
  position: relative;
  margin-bottom: 40px;
  &:before {
    content: "";
    padding-top: 60%;
    display: block;
  }
  > div {
    top: 0px;
    bottom: 0px;
  }
}

.map-info-window {
  width: 500px;
  position: relative;
  padding: 18px 30px 30px 18px;
  font-family: "Montserrat", sans-serif;
  .group-detail-link {
    position: absolute;
    right: 0px;
    z-index: 1;
    i {
      font-size: 16px;
      margin-left: 10px;
    }
    a {
      display: flex;
      align-items: center;
      color: $insight-secondary;
      font-size: 10px;
      font-weight: 700;
      text-transform: uppercase;
      transition: color 0.36s ease;
      &:hover {
        text-decoration: none;
        color: $insight-primary;
      }
    }
  }
  .group-name {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: $insight-gray;
    padding-bottom: 24px;
    margin-bottom: 18px;
    position: relative;
    max-width: 250px;
    &:after {
      width: 24px;
      height: 3px;
      content: "";
      position: absolute;
      bottom: 0px;
      left: 0px;
      background: #fcdc95;
    }
    h4 {
      margin: 0px;
      color: $insight-primary;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
    }
  }
  .group-detail-row {
    padding: 20px 0px;
    border-bottom: solid 1px $insight-light-gray;
    span {
      font-size: 12px;
      line-height: 18px;
      font-weight: 700;
      text-transform: uppercase;
      color: $insight-gray;
      display: block;
      margin-bottom: 0px;
    }
    p {
      margin: 8px 0px 0px 0px;
      font-weight: 700;
      font-size: 16px;
      line-height: 27px;
    }
  }
}
