@import '../../../../../shared/static/styles/partials/variables';

.wrapper {
  display: flex;
  align-items: center;
}

.barContainer {
  flex: 1;
  height: 8px;
  background: #dfdfe7;
  border-radius: 12px;
  overflow: hidden; /* Crucial part */
}

.textValue {
  margin-left: 8px;
  width: 42px;
  text-align: right;
}

.fillerBackground {
  height: inherit;
  transition: 'width 2s ease-i-out';
  background: linear-gradient(90deg, purple, darkorange);
}

.filler {
  transition: 'width 2s ease-i-out';
  height: inherit;
  border-radius: inherit;
  overflow: hidden;
  width: 42px;
}

.empty {
  padding: 50px 0;
}

.scroll {
  height: 50%;
  overflow: hidden;
  position: relative;
  * {
    ::-webkit-scrollbar {
      width: 6px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background-color: white;
      border-radius: 3px;
      border: 1px solid $insight-light-gray;
    }
    scrollbar-width: thin;
    scrollbar-color: white transparent;
  }

  .track {
    position: absolute;
    top: 0;
    right: 2px;
    width: 2px;
    background-color: $insight-yellow;
    height: 100%;
    border-radius: 2px;
    z-index: 1;
  }
}

.track {
  position: absolute;
  top: 0;
  right: 2px;
  width: 2px;
  background-color: $insight-yellow;
  height: 100%;
  border-radius: 2px;
  z-index: 1;
}

.item {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba($color: $insight-primary, $alpha: 0.02);
  padding: 16px;
  max-height: 75px;

  .header-row {
    box-shadow: none;
    .header-col {
      background-color: white;
      position: sticky;
      top: 0;
      padding-bottom: 5px;
      z-index: 1;
    }
  }
}

.scroll {
  max-height: 300px;
}

.option {
  max-width: 150px !important;
}

.wrap {
  border-bottom: 1px solid $insight-light-gray;
  padding-top: 22px;
  padding-bottom: 22px;
  margin: 0;
  &:last-of-type {
    border-bottom: none;
  }
  &:hover {
    .option {
      color: $insight-primary;
    }
  }
}

.reduceOpacity {
  opacity: 0.4 !important;
  z-index: 500 !important;
}

.defaultCursor {
  cursor: default !important;
}
