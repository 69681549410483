@import '../../../../../shared/static/styles/partials/variables';

.list {
  position: relative;
}


.tableContentHeight {
  height: calc(100vh - 300px);
}


.header {
 padding: 0 24px 16px;
 font-size: 14px;
 font-weight: 600;
 color: $insight-gray;
}

.footer {
  margin-top: 1rem;
  margin-bottom: 6rem;
}

.empty{
  padding-top: 68px;
  h2{
    margin-bottom: 65px;
  }
  img{
    width: 212px;
    display: block;
    margin: 0 auto 50px;
  }
  
  p{
    margin-top: 30px;
  }
}

.unnamedColumn {
  min-width: 350px;
}

.date {
  min-width: 175px;
}

.name {
  @media screen and (min-width: 1300px) {
    max-width: 170px !important;
  }
  @media screen and (min-width: 1400px) {
    max-width: 230px !important;
  }
}

.doubleArrow {
  opacity: 1;
}

.emptyAlert {
  margin-top: 30px;
  @media screen and (min-width: 1300px) and (max-width: 1400px) {
    margin-top: 120px;
  }
}