.panel {
    width: 100%;
    height: 100%;
  }
  
  .scrollContainer {
    max-height: 220px;
    margin-top: 1rem;
  }
  
  .reduceOpacity {
    opacity: 0.4 !important;
    z-index: 500 !important;
    pointer-events: none !important;
  }
  
  .checkbox {
    min-width: 16px;
  }
  