@import '../../../../../shared/static/styles/partials/variables';

.search {
  border: none;
  border-bottom: 2px solid $insight-light-gray;
  background-color: transparent;
  width: 100%;
  padding: 8px 16px;
  margin-top: 32px;
  color: $insight-gray;
  font-weight: 600;
  margin-bottom: 16px;
  &::placeholder {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    color: $insight-gray;
  }
  &:hover {
    border-bottom-color: $insight-yellow;
  }
  &:focus {
    border-bottom-color: $insight-secondary;
  }
}

.cloud {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  .tag {
    height: 40px;
    padding: 6px 20px;
    display: flex;
    border-radius: 20px;
    background-color: white;
    border: 1px solid $insight-orange;
    font-size: 14px;
    text-transform: uppercase;
    color: $insight-gray;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-right: 10px;
    line-height: 1;
    &.loading {
      opacity: 0.5;
      pointer-events: none;
    }
    i {
      font-size: 16px;
      margin-right: 10px;
      &.warning {
        margin-right: 10px;
      }
    }
  }
}
