@import '../../../../../../shared/static/styles/partials/variables';

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border: 3px solid $insight-secondary;
  border-radius: 50%;
  margin-top: 30px;
  margin-bottom: 30px;
  i {
    color: $insight-secondary;
  }
}

.anim {
  max-height: 191px;
  max-width: 191px;
}
