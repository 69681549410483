.textBlackOpacity40 {
    color: rgba(0, 0, 0, 0.4) !important;
}

.textBlackOpacity28 {
    color: rgba(0, 0, 0, 0.28) !important;
}

.noPointerEvents {
    pointer-events: none !important;
}

.bodyHeight {
    height: 140px !important;
    resize: none;
}