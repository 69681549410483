@import '../../../../../shared/static/styles/partials/variables';

.overlay {
  position: fixed;
  background-color: transparentize($color: #141414, $amount: 0.56);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.confirmation {
  border: 1px solid $insight-orange;
  z-index: 600;
  position: relative;
  border-radius: 4px;
  width: 456px;
  padding: 31px 26px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.success {
    border: 1px solid $insight-light-gray;
    width: 100%;
    max-width: 512px;
  }
  &.warning {
    width: 100%;
    max-width: 512px;
  }
}

.anim {
  max-height: 150px;
  max-width: 150px;
  padding-right: 30px;
}

.scrollContainer {
  max-height: 100px;
  width: 100%;

  ul {
    display: block;
    width: 100%;
    max-width: max-content;
    margin: 0 auto 15px;

    li {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      width: auto;
      max-width: 300px;
      &::before {
        content: '';
        min-width: 4px;
        min-height: 4px;
        border-radius: 50%;
        background-color: $insight-gray;
        margin-right: 15px;
      }
    }
  }
}

.textDanger {
  color: $insight-orange !important;
}
