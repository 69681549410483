@import '../../../../../shared/static/styles/partials/variables';

.image{
  padding-right: 27px;
  display: flex;
  align-items: center;
  img{
    width: 65px;
  }
}

.wrapper{
  padding: 1rem;
}

.card{
  transition: background-color 0.36s ease;
  &:hover{
    background-color: lighten($color: $insight-bg-yellow, $amount: 2);
  }
  &.active{
    border-color: $insight-secondary;
    background-color: $insight-bg-yellow;
    .title{
      h4{
        color: $insight-secondary;
      }
      .check{
        display: inline-block;
      }
    }
    img{
      opacity: 1;
    }
  }
  .img{
    opacity: 0.45;
  }
  .title{
    margin-bottom: 11px;
    h4{
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 0;
      line-height: 24px;
    }
    .check{
      display: none;
      background-color: $insight-secondary;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      line-height: 15px;
      text-align: center;
      margin-left: 8px;
      i{
        font-size: 10px;
        color: white;
      }
    }
  }
  .text{
    font-size: 12px;
    line-height: 18px;
    color: $insight-gray;
    font-weight: 500;
  }
}


