//Colors

$insight-primary: #324b5a;
$insight-secondary: #68c7c2;
$insight-secondary-dark: #07ada5;
$insight-light: #f2f2f2;
$insight-lighter:#F7FDFD;
$insight-dark: #435a67;
$insight-orange: #f3835a;
$insight-yellow: #fcdc95;
$insight-mild-yellow: #f8e9c5;
$insight-light-yellow: #fbf3df;
$insight-bg-yellow: #fafaf5;
$insight-gray: #738088;
$insight-light-gray: #dbdce1;
$insight-error: #dc3545;
$insight-blue: #5ab4e7;
$insight-lavender-magenta: #f19bdd;
$insight-cornflower: #93ceef;
$insight-spring-wood: #fafaf5;
$insight-jaffa: #f28243;

$insight-colors: () !default;
$insight-colors: (
  "primary": $insight-primary,
  "secondary": $insight-secondary,
  "secondary-dark": $insight-secondary-dark,
  "light": $insight-light,
  "dark": $insight-dark,
  "orange": $insight-orange,
  "yellow": $insight-yellow,
  "mild-yellow": $insight-mild-yellow,
  "light-yellow": $insight-light-yellow,
  "bg-yellow": $insight-bg-yellow,
  "gray": $insight-gray,
  "light-gray": $insight-light-gray,
  "error": $insight-error,
  "blue": $insight-blue
);

$insight-export-colors: () !default;
$insight-export-colors: (
  "primary": $insight-primary,
  "blue": $insight-blue,
  "secondary": $insight-secondary,
  "secondaryDark": $insight-secondary-dark,
  "light": $insight-light,
  "dark": $insight-dark,
  "orange": $insight-orange,
  "yellow": $insight-yellow,
  "mildYellow": $insight-mild-yellow,
  "lightYellow": $insight-light-yellow,
  "bgYellow": $insight-bg-yellow,
  "gray": $insight-gray,
  "lightGray": $insight-light-gray,
  "lavenderMagenta": $insight-lavender-magenta,
  "cornflower": $insight-cornflower,
  "springWood": $insight-spring-wood,
  "jaffa": $insight-jaffa,
  "error": $insight-error
);

:root {
  --insight-primary: $insight-primary;
  --insight-secondary: $insight-secondary;
  --insight-secondary-dark: $insight-secondary-dark;
  --insight-light: $insight-light;
  --insight-dark: $insight-dark;
  --insight-orange: $insight-orange;
  --insight-yellow: $insight-yellow;
  --insight-mild-yellow: $insight-mild-yellow;
  --insight-light-yellow: $insight-light-yellow;
  --insight-bg-yellow: $insight-bg-yellow;
  --insight-gray: $insight-gray;
  --insight-light-gray: $insight-light-gray;
  --insight-error: $insight-error;
  --insight-blue: $insight-blue;
  --insight-lavender-magenta: $insight-lavender-magenta;
  --insight-cornflower: $insight-cornflower;
  --insight-spring-wood: $insight-spring-wood;
  --insight-jaffa: $insight-jaffa;
}
