@import '../../../../../shared/static/styles/partials/variables';

.divider {
  padding-bottom: 30px;
}

.empty {
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba($color: white, $alpha: 0.8);
  img {
    width: 212px;
    display: block;
    margin: 0 auto 50px;
  }
  p {
    margin-top: 30px;
  }
}

.skeleton {
  border-color: transparent;
  margin-bottom: 32px;
  padding: 30px;
  border-radius: 4px;
  width: 100%;
}

.item {
  box-shadow: 0px 4px 4px rgba($color: $insight-primary, $alpha: 0.02);
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  border-color: transparent;
  margin-bottom: 32px;
  background-color: white;
  padding: 30px;
  border-radius: 4px;

  &.error {
    p,
    a,
    .btn {
      color: $insight-orange;
      &:focus {
        color: $insight-orange;
        outline: none !important;
        background-color: #ffffff !important;
        border-color: $insight-light-gray;
      }
    }
    .btn {
      border-color: $insight-orange;
    }
  }

  a:not(.btn) {
    color: $insight-primary;
    text-decoration: underline;
  }

  p {
    margin-bottom: 0;
    max-width: 100%;

    i {
      display: inline-block;
      vertical-align: middle;
    }
  }

  &.dummy {
    border: 1px solid $insight-orange;
    margin-bottom: 50px;
  }

  &.collapse {
    height: auto;
    max-height: none;

    .checkboxList {
      margin-bottom: 77px;
    }

    .checkbox {
      display: grid;
      grid-template-columns: 16px auto;
      align-items: center;
      grid-gap: 24px;
      color: $insight-gray;
      width: fit-content;
      &.checked {
        color: $insight-primary;
      }
    }

    textarea {
      min-height: 99px;
    }

    .formWrap {
      margin-bottom: 45px;
    }
  }
}
