@import './variables';

.breadcrumb {
  background-color: transparent;
  padding: 0;
  margin: 0;
  .breadcrumb-item {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    max-width: 400px;
    display: flex;
    align-items: center;
    & + .breadcrumb-item {
      &::before {
        content: '>';
        color: $insight-primary;
      }
    }
    &.active {
      color: $insight-primary;
    }
    .truncate {
      max-width: 76%;
    }
  }
}