@import '../../../../shared/static/styles/partials/variables';

.scroll{
  max-height: 250px;
}

.option {
  max-width: 150px !important;
}

.wrap {
  border-bottom: 1px solid $insight-light-gray;
  padding-top: 22px;
  padding-bottom: 22px;
  margin: 0;
  &:last-of-type {
    border-bottom: none;
  }
  &:hover {
    .option {
      color: $insight-primary;
    }
  }
}

.reduceOpacity {
  opacity: 0.4 !important;
  z-index: 500 !important;
}

.defaultCursor {
  cursor: default !important;
}