@import '../../../shared/static/styles/partials/variables';

.overlay {
    position: fixed;
    background-color: transparentize($color: white, $amount: 0.5);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .confirmation {
    border: 1px solid $insight-orange;
    z-index: 110;
    position: relative;
    border-radius: 4px;
    width: 309px;
    padding: 31px 26px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    div,
    button:first-of-type {
      margin-bottom: 24px;
    }
  }