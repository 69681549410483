.image {
  padding-left: 0px !important;
  padding-bottom: 20px;
  padding-top: 30px;
  padding-right: 0px !important;
  img {
    width: 100%;
    max-width: 380px;
    min-width: 260px;
  }
}

.textContainer {
  margin-bottom: 31px;
}